import React, {useEffect} from "react";
import {useSocketIo} from "../lib/socketio-with-auth";

const RealtimeAssetFeed = ({onData}) => {
  const socket = useSocketIo();

  useEffect(() => {
    if(!socket) return;

    socket.on("asset:gps:all", onData);

    return () => {
      socket.removeListener("asset:gps:all", onData);
    }
  }, [socket, onData]);

  return null;
}

export default RealtimeAssetFeed;