const chained = (Component, ContextChain) => {
    let ComponentNested = Component;

    for (let i in ContextChain){
        if (Array.isArray(ContextChain[i]) && ContextChain[i].length === 2){
            const context   = ContextChain[i][0];
            let contextFunc = ContextChain[i][1];
            if (typeof contextFunc !== "function"){
                const propKey = "" + contextFunc;
                contextFunc = (a) => {
                    return {
                        [propKey]: a
                    }
                }
            }
            ComponentNested = context(ComponentNested, contextFunc);
        }
    }
    return ComponentNested;
};
export default chained;
