export default {
  api_server: "/api",
  socket_server: "/",
  gMapKey: "AIzaSyC_fC7qakfSDBP3jWWvrcZwBtVi99wboAo",
  bing_key: "AoMKVVkBRY3_8gv8UJmlIHXp8DCIGGzv9Yw_50Q4WIqC5ujgtVszQK-xLpB34hF2",
  localStoragePrefix: "sarmap-prod__",
  auth: {
    clientId: "5d4ab4a8bda24a26ba93774a"
  }
};
