import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Menu, Transition, Tab } from "@headlessui/react";
import dayjs from "dayjs";
import {
  ArrowTrendingDownIcon,
  Bars3Icon,
  EllipsisVerticalIcon,
  MapPinIcon,
} from "@heroicons/react/20/solid";
import { ClockIcon, XMarkIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function AssetBar({ assets, flyTo, latest, HandleOpenHistory }) {
  return (
    <ul role="list" className="h-full divide-y   divide-gray-400  mt-2 pb-10">
      {assets.map((asset) => {
        const when = asset._lastGPS !== null ? dayjs().diff(asset._lastGPS, "minute") : 999;
        return (
          <li key={asset._id}>
            <div className="group relative flex items-center py-4 px-1">
              <div className="-m-1 block flex-1 p-1">
                <div
                  className="absolute inset-0 group-hover:bg-gray-50"
                  aria-hidden="true"
                />
                <div className="relative flex min-w-0 flex-1 items-center">
                  <span className="relative inline-block flex-shrink-0">
                     {asset.assetFeed === "smc_gateway" && (
                       <img
                         className="h-10 w-10 rounded-full"
                         src={require("../assets/images/smc_gateway-small.png")}
                         alt=""
                       />
                     )}
                    {asset.assetFeed === "zello" && (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={require("../assets/images/zello.png")}
                        alt=""
                      />
                    )}
                    {asset.assetFeed === "quartix" && (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={require("../assets/images/quartix.png")}
                        alt=""
                      />
                    )}
                    {asset.assetFeed === "app" && (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={require("../assets/images/tracker.png")}
                        alt=""
                      />
                    )}
                    <span
                      className={classNames(
                        when > 15 ? "bg-yellow-700" : "bg-green-500",
                        "absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white"
                      )}
                      aria-hidden="true"
                    />
                  </span>
                  <div className="ml-4 truncate">
                    <p className="truncate text-sm font-medium text-gray-900">
                      {asset.title}
                      {asset.assetFeed === "quartix" && (
                        <span className="ml-2 italic text-xs">
                          [ {asset._lastGPS !== null ? asset._location.properties.quartix_driver : ""} ]
                        </span>
                      )}
                    </p>
                    <p className="truncate text-sm text-gray-500">
                      {asset.assetID}
                    </p>
                    <p className="truncate text-sm text-gray-500">
                      Last GPS: {asset._lastGPS === null ? "[N/A]" : dayjs(asset._lastGPS).format("DD/MM/YYYY HH:mm:ss")}
                    </p>
                  </div>
                </div>
              </div>
              <Menu
                as="div"
                className="relative ml-2 inline-block flex-shrink-0 text-left"
              >
                <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="sr-only">Open assets menu</span>
                  <span className="flex h-full w-full items-center justify-center rounded-full">
                    <EllipsisVerticalIcon
                      className={`h-5 w-5 "text-gray-400 group-hover:text-gray-500`}
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute top-0 right-9 z-10 w-48 origin-top-right rounded-md bg-white  ">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() =>
                              flyTo(asset._location.geometry.coordinates)
                            }
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "flex gap-2 px-4 py-2 text-sm items-center cursor-pointer"
                            )}
                          >
                            <MapPinIcon className="h-6" /> Go To
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              latest(asset._id);
                              flyTo(asset._location.geometry.coordinates);
                            }}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "flex gap-2  px-4 py-2 text-sm items-center cursor-pointer"
                            )}
                          >
                            <ArrowTrendingDownIcon className="h-6" /> Show Track
                          </div>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              HandleOpenHistory(asset);
                              flyTo(asset._location.geometry.coordinates);
                            }}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "flex gap-2  px-4 py-2 text-sm items-center cursor-pointer"
                            )}
                          >
                            <ClockIcon className="h-6" /> History
                          </div>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </li>
        );
      })}
    </ul>
  );
}
