import React, { useState, useEffect, Fragment } from "react";
import { Authentication } from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import MapboxMap from "../components/mapping/MainMap";
import { Organisation_Receiver } from "../contexts/organisation";
import ChainedContext from "../contexts/chainedContext";
import api from "../api";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowDownOnSquareIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Bars3Icon } from "@heroicons/react/20/solid";
import AssetBar from "../components/AssetBar";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import RealtimeAssetFeed from "../components/RealtimeAssetFeed";
import createGpx from "gps-to-gpx";
import { toast } from "react-hot-toast";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const returnTabButtonStyle = (selected, smaller) => {
  return classNames("w-full py-2.5  leading-5 font-medium  rounded-lg", selected ? "text-gray-900 " : "text-gray-300 ", smaller ? "text-md" : "text-lg");
};

class UpdateBucketManager {
  bucket = [];

  addItem(item) {
    this.bucket.push(item);
  }
  getNextItem() {
    const data = this.bucket.shift();
    if (data === undefined) return false;
    return data;
  }
  hasData() {
    return this.bucket.length > 0;
  }
}
const UpdateBucket = new UpdateBucketManager();

function HomePage({ org }) {
  const [drainUpdates, setDrainUpdates] = useState(false);
  const [showOffine, setShowOffine] = useState(false);
  const [assets, setAssets] = useState([]);
  const [assetsOnline, setOnlineAssets] = useState([]);
  const [assetsOffline, setOfflineAssets] = useState([]);
  const [data, setData] = useState({
    from: new Date(),
    to: new Date(),
  });
  const [asset, setAsset] = useState([]);
  const [user, setUser] = useState({});
  const [latest, setLatest] = useState([]);
  const [trackHistory, setHistory] = useState([]);
  const [flyto, setFlyto] = useState([]);
  const [openMenu, setOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [animation, setAnimate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  /*
   * API Calls
   */
  const getData = () => {
    api.assets.all().then((res) => setAssets(res));
  };
  const getLatest = (id) => {
    return api.assets.last10(id).then((res) => {
      const locations = res
        .map((loc) => {
          if (loc.location) {
            return loc.location.geometry.coordinates;
          }
          return null;
        })
        .filter((i) => i !== null);

      let track = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: locations.reverse(),
        },
      };
      setLatest(track);
      if (asset._location.geometry) {
        setFlyto(track.geometry.coordinates.reverse()[0]);
      }

      setOpen(false);
    });
  };
  const getHistory = () => {
    return api.assets.history(asset._id, dayjs(data.from).toISOString(), dayjs(data.to).toISOString()).then((res) => {
      const locations = res
        .map((loc) => {
          if (loc.location) {
            return loc.location.geometry.coordinates;
          }
          return null;
        })
        .filter((i) => i !== null);

      let track = {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: locations.length ? locations.reverse() : [],
        },
      };
      if (locations.length === 0) {
        toast.error("No locations stored.");
      }
      setOpenHistory(false);
      setHistory(track);
      setOpen(false);
    });
  };

  /*
   * useEffects
   */
  useEffect(() => {
    getData();
    setUser(Authentication.getUserData());

    const updateAssetList = setInterval(() => getData(), 60000);
    const drainChecker = setInterval(() => {
      if (UpdateBucket.hasData()) {
        setDrainUpdates(true);
      }
    }, 2000);
    return () => {
      clearInterval(updateAssetList);
      clearInterval(drainChecker);
    };
  }, []);
  useEffect(() => {
    setOnlineAssets(assets.filter((a) => a._lastGPS && dayjs(a._lastGPS) > dayjs().subtract(30, "minute")));
    setOfflineAssets(
      assets
        .filter((a) => (a._lastGPS === undefined ? true : dayjs(a._lastGPS) < dayjs().subtract(30, "minute")))
        .map((a) => {
          return {
            ...a,
            _lastGPS: a._lastGPS === undefined ? null : a._lastGPS,
          };
        })
    );
  }, [assets]);
  useEffect(() => {
    if (!drainUpdates) return;

    const data = UpdateBucket.getNextItem();
    if (data === false) {
      setDrainUpdates(false);
      return;
    }
    setAssets(
      assets.map((a) => {
        if (a._id !== data.asset) return a;
        return {
          ...a,
          _location: data.location,
          _lastGPS: data.location.properties.gpsDate,
        };
      })
    );
  }, [drainUpdates, assets]);

  /*
   * Handlers
   */
  const resetMap = () => {
    setReset(true);
    setAsset({});
    setFlyto("");
    setLatest([]);
    setHistory([]);
    setOpen(false);
    setData({ from: new Date(), to: new Date() });
    setTimeout(() => {
      setReset(false);
    }, 1000);
  };
  const filterList = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };
  const getFilteredAssets = () => {
    const onlineResults = assetsOnline.filter((item) => {
      if (searchQuery === null || searchQuery.trim() === "") {
        return true;
      }
      let titleMatch = item.title ? item.title.toLowerCase().search(searchQuery) !== -1 : false;
      let assetID = item.assetID ? item.assetID.toLowerCase().search(searchQuery) !== -1 : false;
      return titleMatch || assetID;
    });
    const offlineResults = assetsOffline.filter((item) => {
      if (searchQuery === null || searchQuery.trim() === "") {
        return true;
      }
      let titleMatch = item.title ? item.title.toLowerCase().search(searchQuery) !== -1 : false;
      let assetID = item.assetID ? item.assetID.toLowerCase().search(searchQuery) !== -1 : false;
      return titleMatch || assetID;
    });
    if (showOffine) {
      return onlineResults.concat(offlineResults);
    }
    return onlineResults;
  };
  const HandleOpenHistory = (asset) => {
    setAsset(asset);
    setOpenHistory(true);
  };

  /*
   * Renderers
   */
  const renderHistoryModal = () => {
    return (
      <Transition.Root show={openHistory} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpenHistory}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform  rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpenHistory(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  sm:mx-0 sm:h-10 sm:w-10">
                      <span className="relative inline-block flex-shrink-0">
                        {asset.assetFeed === "quartix" && <img className="h-10 w-10 rounded-full" src={require("../assets/images/quartix.png")} alt="" />}
                        {asset.assetFeed === "app" && <img className="h-10 w-10 rounded-full" src={require("../assets/images/tracker.png")} alt="" />}
                      </span>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-2 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 pb-2">
                        Show history for {asset.title ? asset.title : "--"}
                      </Dialog.Title>
                      <div className="mt-2 flex gap-2">
                        <div className="w-full ">
                          <label htmlFor={"dateMissing"} className={`block text-sm font-medium text-gray-500 `}>
                            Date From
                          </label>
                          <DatePicker
                            onChange={(date) => {
                              setData({ ...data, from: date });
                            }}
                            selected={data.from ? new Date(data.from) : ""}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={10}
                            // showTimeInput
                            // shouldCloseOnSelect={false}
                            timeInputLabel="Time:"
                            dateFormat="dd-MM-yy HH:mm"
                            maxDate={new Date()}
                            nextMonthButtonLabel=">"
                            previousMonthButtonLabel="<"
                            className=" bg-gray-200 p-2 border border-gray-400 rounded"
                            data-disable-touch-keyboard
                          />
                        </div>
                        <div className="w-full ">
                          <label htmlFor={"dateMissing"} className={`block text-sm font-medium text-gray-500 `}>
                            Date To
                          </label>
                          <DatePicker
                            onChange={(date) => {
                              setData({ ...data, to: date });
                            }}
                            selected={data.to ? new Date(data.to) : ""}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={10}
                            // showTimeInput
                            // shouldCloseOnSelect={false}
                            timeInputLabel="Time:"
                            dateFormat="dd-MM-yy HH:mm"
                            maxDate={new Date()}
                            minDate={data.from ? data.from : ""}
                            nextMonthButtonLabel=">"
                            previousMonthButtonLabel="<"
                            className=" bg-gray-200 p-2 border border-gray-400 rounded"
                            data-disable-touch-keyboard
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => getHistory()}
                    >
                      Show Track
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setOpenHistory(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };
  const renderMenu = () => {
    return (
      <React.Fragment>
        {!openMenu && (
          <button onClick={() => setOpen(true)} className="absolute top-5 right-5 bg-gray-800 bg-opacity-60 text-white rounded z-50 p-2">
            <Bars3Icon className="h-6" />
          </button>
        )}
        <Transition.Root show={openMenu} as={Fragment}>
          <Dialog as="div" className="relative z-40" onClose={setOpen}>
            <div className="fixed inset-0" />
            <div className="fixed inset-0 ">
              <div className="absolute inset-0 ">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md overflow-scroll bg-white">
                      <div className="flex h-full flex-col  bg-white ">
                        <div className="p-4">
                          <div className="flex items-start justify-between">
                            <Dialog.Title className="text-lg font-medium text-gray-900">GPS Assets</Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="p-4">
                          <button className="bg-gray-50 rounded py-1 px-2 shadow-md shadow-blue-200 hover:bg-gray-100" onClick={() => resetMap()}>
                            Reset
                          </button>
                          <button
                            className={`ml-4 ${!showOffine ? "bg-red-50 shadow-red-200" : "bg-green-50 shadow-green-200"}  rounded py-1 px-2 shadow-md  hover:bg-gray-100`}
                            onClick={() => setShowOffine(!showOffine)}
                          >
                            {showOffine ? "Show Online" : "Show Offline"}
                          </button>
                        </div>
                        <div className="overflow-scroll1 pb-20">
                          <div className="px-2 ">
                            <input onChange={filterList} placeholder="search" value={searchQuery} className="p-2 border border-gray-300 rounded bg-gray-100 w-full" />
                            <AssetBar assets={getFilteredAssets()} flyTo={(c) => setFlyto(c)} latest={(a) => getLatest(a)} HandleOpenHistory={(d) => HandleOpenHistory(d)} />
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </React.Fragment>
    );
  };
  const renderFooter = () => {
    return (
      <div style={{ height: "7vh" }} className=" bg-gray-50 p-2 flex justify-between items-center border-t border-gray-300">
        {user.organisation && <div className="text-lg p-2">{user.organisation.name}</div>}
        <button className="p-1" onClick={() => Authentication.logout()}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-blue-600 hover:text-red-600">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
            />
          </svg>
        </button>
      </div>
    );
  };
  const downloadGPX = () => {
    if (trackHistory.geometry && trackHistory.geometry.coordinates.length) {
      let path = [];
      if (trackHistory.geometry.type === "Polygon") {
        path = trackHistory.geometry.coordinates[0].map((loc) => {
          return {
            latitude: loc[1],
            longitude: loc[0],
          };
        });
      } else {
        path = trackHistory.geometry.coordinates.map((loc) => {
          return {
            latitude: loc[1],
            longitude: loc[0],
          };
        });
      }

      const theData = {
        gpx: {
          activityType: "Search",
          waypoints: path,
        },
      };
      // setGPX(theData);
      // const file = new Blob([createWaypointGPX(theData.gpx)], {
      //   type: "text/plain",
      // });
      const gpx = createGpx(theData.gpx.waypoints, {
        activityName: asset.title,
      });

      let file = new Blob([gpx], { type: "text/plain" });
      let element = document.createElement("a");
      element.href = URL.createObjectURL(file);
      element.download = `${asset.title}.gpx`;
      element.click();
    }
  };
  // console.log(org);
  return (
    <div className="w-full relative">
      <RealtimeAssetFeed
        all={true}
        onData={(data) => {
          // console.log("RealtimeAssetFeed", data);
          UpdateBucket.addItem(data);
        }}
      />

      {renderHistoryModal()}

      <div className="bg-gray-800 bg-opacity-70 border-2  border-gray-200 shadow-lg absolute flex gap-4 top-5 left-5  z-50 py-2 px-4 text-white rounded">
        <span className="text-green-300 tracking-wider">Active: {assetsOnline.length}</span>
        <span className="text-red-300 tracking-wider">Offline: {assetsOffline.length}</span>
      </div>
      {asset._id && (
        <div className="bg-gray-800 bg-opacity-70 border-2  border-gray-200 shadow-lg absolute flex flex-col items-center gap-2 top-20 left-5  z-50 py-2 px-4 text-white rounded">
          <div className="flex items-center gap-4  text-white ">
            <span className="text-white tracking-wider">Asset: {asset.title}</span>
            {trackHistory.type === "Feature" && trackHistory.geometry && trackHistory.geometry.coordinates.length > 0 && (
              <button onClick={downloadGPX} className="text-sm rounded border-gray-700 border p-1 flex gap-2 items-center">
                <ArrowDownOnSquareIcon className="h-5" />
                GPX
              </button>
            )}
            <button onClick={() => setAsset({})} className={`text-sm  p-1 flex hover:text-red-600 text-red-300 items-center`}>
              <XMarkIcon className="h-4 " />
            </button>
          </div>
          <div className="pt-2 border-t w-full border-gray-200 flex gap-4">
            <div className="text-sm tracking-wider pl-2">
              Last heard: <span>{dayjs(asset._lastGPS).format("DD-MM-YY HH:mm")}</span>
            </div>
          </div>
          <div className="pt-2 border-t w-full border-gray-200 flex gap-4">
            <button onClick={() => getLatest(asset._id)} className="border border-gray-400 rounded py-1 px-2 bg-gray-900 bg-opacity-30">
              Latest
            </button>
            <button onClick={() => HandleOpenHistory(asset)} className="border border-gray-400 rounded py-1 px-2 bg-gray-900 bg-opacity-30">
              History
            </button>
          </div>
        </div>
      )}
      <MapboxMap
        mapCenter={org.organisation ? org.organisation.defaultMapCenter : [0.67, 51.8]}
        assets={getFilteredAssets().filter((a) => a._lastGPS !== null)}
        offlineAssets={assetsOffline.filter((a) => a._location.geometry !== undefined)}
        flyto={flyto}
        last10={latest}
        animate={animation}
        trackHistory={trackHistory}
        reset={reset}
        assetClick={(e) => setAsset(e)}
        showOS={org.organisation.gridType === "OSGB"}
      />

      {renderMenu()}
      {renderFooter()}
    </div>
  );
}
export default ChainedContext(HomePage, [[Organisation_Receiver, "org"]]);
