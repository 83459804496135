import React from "react";

import Context from "./context";

export default class OrganisationProvider extends React.Component {
  state = {
    isFirstLoading: true,
    organisation: {},
  };
  componentDidMount() {
    this.refreshData();
  }
  refreshData = () => {
    this.setState({
      organisation: this.props.loadData(),
      isFirstLoading: false,
    });
    // this.props
    //   .loadData()
    //   .then((res) => {
    //     this.setState({
    //       organisation: res,
    //     });
    //   })
    //   .catch((err) => {
    //     // TODO
    //   })
    //   .finally(() => {
    //     this.setState({
    //       isFirstLoading: false,
    //     });
    //   });
  };
  render() {
    return (
      <div className={"no-marg no-pad"}>
        <Context.Provider
          value={{
            _refresh: this.refreshData,
            organisation: this.state.organisation,
          }}
        >
          {this.state.isFirstLoading === false ? (
            this.props.children
          ) : (
            <div>Loading Organisation...</div>
          )}
        </Context.Provider>
      </div>
    );
  }
}
