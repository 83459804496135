import React, { useState, useRef, useEffect, useCallback } from "react";
import ReactMapboxGl, { ZoomControl, ScaleControl, GeoJSONLayer, Source, Layer, Feature } from "react-mapbox-gl";
import OSLogo from "../../assets/images/os-logo.png";
import dayjs from "dayjs";
import * as turf from "@turf/turf";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
const { token, styles } = require("./mapbox.json");
const Map = ReactMapboxGl({
  accessToken: token,
  preserveDrawingBuffer: true,
  // customAttribution: "SARStuff mapping",
});
// var options = { steps: 64, units: "meters", properties: {} };
// const selectedStyles = ["local", "sat", "outdoor"];
// const switchStyles = Object.keys(styles).filter((k) =>
//   selectedStyles.includes(k)
// );

export default function MainMap({
  mapCenter,
  assets,
  assetsOffline = [],
  mapStyle = {
    minHeight: "93vh",
  },
  flyto,
  last10,
  trackHistory,
  reset,
  children,
  assetClick,
  showOS,
}) {
  const [styleKey, setStyle] = useState("sat");
  const [hack, setHack] = useState(true);
  const map = useRef(null);

  let locData = {
    type: "FeatureCollection",
    features: assets
      ? assets.map((area) => {
          return area._location;
        })
      : [],
  };
  let offlinelocData = {
    type: "FeatureCollection",
    features: assetsOffline
      ? assetsOffline.map((area) => {
          return area._location;
        })
      : [],
  };

  const uk_styles = ["outdoor3", "sat", "local", "os125"];
  const non_uk_styles = ["outdoor3", "sat"];
  const switchStyles = Object.keys(styles).filter((k) => (showOS ? uk_styles.includes(k) : non_uk_styles.includes(k)));

  useEffect(() => {
    if (flyto.length && map.current && map.current.state.map) {
      map.current.state.map.flyTo({
        center: flyto,
        zoom: 16,
        bearing: 0,
        pitch: 0,
      });
    }
  }, [flyto]);
  useEffect(() => {
    if (assets.length && hack) {
      getBounds();
      setHack(false);
    }
  }, [assets, hack]);
  useEffect(() => {
    if (trackHistory.type === "Feature" && trackHistory.geometry && trackHistory.geometry.coordinates.length) {
      if (map.current && map.current.state) {
        var bounds = turf.bbox(trackHistory);
        map.current.state.map.fitBounds(bounds, { padding: 80, duration: 0 });
      }
    }
  }, [trackHistory]);
  useEffect(() => {
    if (assets.length && reset) {
      getBounds();
    }
  }, [reset]);
  const nextStyle = () => {
    const currentIndex = switchStyles.indexOf(styleKey);
    const nextIndex = currentIndex === switchStyles.length - 1 ? 0 : currentIndex + 1;

    setStyle(switchStyles[nextIndex]);
  };
  const onStyleLoad = () => {
    // setHack(false);
  };
  const getBounds = useCallback(() => {
    if (map.current && map.current.state && map.current.state.map) {
      if (assets.length > 0) {
        var bounds = turf.bbox(locData);
        map.current.state.map.fitBounds(bounds, { padding: 80, duration: 0 });
      }
    }
  }, [assets]);
  const renderOnScreenButton = (title, isActive, onClick, tooltip, left = false) => {
    return (
      <button
        onClick={() => onClick()}
        className={`has-tooltip hover:bg-gray-600 hover:text-gray-100 cursor-pointer tracking-wider flex justify-center text-center align-middle border ${
          isActive ? "bg-green-800 text-white border-green-400" : "bg-gray-200 border-gray-300"
        } p-2 bg-opacity-80 text-black rounded-lg`}
      >
        {title}
      </button>
    );
  };
  // console.log(trackHistory);
  return (
    <div className="w-full">
      <Map ref={map} center={mapCenter} style={styles[styleKey]} onStyleLoad={onStyleLoad} className="baseMap" movingMethod={"jumpTo"} containerStyle={mapStyle}>
        {
          assets.length &&
            assets.map((asset) => {
              const when = asset._lastGPS && dayjs().diff(asset._lastGPS, "minute");
              let colour = "#ff0000";
              switch (true) {
                case when > 15 && when < 30:
                  colour = "#ffff00";
                  break;
                case when < 15:
                  colour = "#00FF00";
                  break;

                default:
                  break;
              }
              const theAsset = {
                ...asset._location,
                properties: {
                  ...asset._location.properties,
                  colour: colour,
                  // onlineStatus: asset.onlineStatus,
                },
              };
              return (
                <GeoJSONLayer
                  key={asset._id}
                  id={asset._id}
                  circleOnClick={() => assetClick(asset)}
                  data={theAsset}
                  symbolPaint={{
                    "text-color": styleKey === "sat" ? "white" : "#000",
                  }}
                  symbolLayout={{
                    "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                    "text-size": 14,
                    "text-transform": "uppercase",
                    "text-field": "{title}",

                    "text-radial-offset": 0.6,
                    "text-variable-anchor": ["top", "bottom", "left", "right"],
                    "text-justify": "auto",
                    "text-letter-spacing": 0.2,
                  }}
                  circlePaint={{
                    "circle-stroke-color": "#000",
                    "circle-stroke-width": 0.5,
                    "circle-color": ["get", "colour"],
                  }}
                />
              );
            })
          // <GeoJSONLayer
          //   id="sarmap_tracking"
          //   circleOnClick={(e) => console.log(e)}
          //   data={{
          //     type: "FeatureCollection",
          //     features: assets.map((asset) => {
          //       const when =
          //         asset._lastGPS && dayjs().diff(asset._lastGPS, "minute");
          //       let colour = "#ff0000";
          //       switch (true) {
          //         case when > 15 && when < 30:
          //           colour = "#ffff00";
          //           break;
          //         case when < 15:
          //           colour = "#00FF00";
          //           break;

          //         default:
          //           break;
          //       }
          //       return {
          //         ...asset._location,
          //         properties: {
          //           ...asset._location.properties,
          //           colour: colour,
          //           // onlineStatus: asset.onlineStatus,
          //         },
          //       };
          //     }),
          //   }}
          //   symbolPaint={{
          //     "text-color": styleKey === "sat" ? "white" : "#000",
          //   }}
          //   symbolLayout={{
          //     "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          //     "text-size": 14,
          //     "text-transform": "uppercase",
          //     "text-field": "{title}",

          //     "text-radial-offset": 0.6,
          //     "text-variable-anchor": ["top", "bottom", "left", "right"],
          //     "text-justify": "auto",
          //     "text-letter-spacing": 0.2,
          //   }}
          //   circlePaint={{
          //     "circle-stroke-color": "#000",
          //     "circle-stroke-width": 0.5,
          //     "circle-color": ["get", "colour"],
          //   }}
          // />
        }
        {last10 && last10.geometry && (
          <GeoJSONLayer
            id="tracks"
            data={last10}
            linePaint={{
              "line-width": 3,
              "line-color": "#FF0000",
            }}
          />
        )}
        {trackHistory && trackHistory.geometry && (
          <GeoJSONLayer
            id="history_track"
            data={trackHistory}
            linePaint={{
              "line-width": 3,
              "line-color": "#FF0000",
            }}
          />
        )}
        <ScaleControl position={"bottom-right"} />
        {styleKey === "local" && <img src={OSLogo} className="OSLogo absolute z-40 left-2 bottom-8 w-28" alt={"Mapping tiles provided by Ordnance Survey"} />}
        {children}
        <div className="absolute top-20 right-3 p-2 gap-4 rounded-lg flex flex-col justify-center text-center">
          {renderOnScreenButton(<GlobeAltIcon className="h-8 text-gray-500" />, false, () => nextStyle(), "Change Map", true)}
        </div>
      </Map>
    </div>
  );
}
