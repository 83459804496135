import AppConfig from "../_appConfig";
import { APIRequest } from "@liquidcomputing/sarstuff__reactjs-singlesignon";

const server = AppConfig.api_server;

const api = {
  assets: {
    all: () => APIRequest.get(server + "/v2/assets").then(res => res.data),
    last10: (id) => APIRequest.get(server + `/v2/assets/${id}/gps/last10`).then(res => res.data),
    history: (id, dateFrom, dateTo) => APIRequest.get(server + `/v2/assets/${id}/gps/history/?dateFrom=${dateFrom}&dateTo=${dateTo}`).then(res => res.data),
  },
};
export default api;