import React, { Suspense } from "react";
import {
  AuthComponents,
  Authentication,
} from "@liquidcomputing/sarstuff__reactjs-singlesignon";
import { OrganisationProvider } from "./contexts/organisation";
import { Routes, Route } from "react-router-dom";
import AppConfig from "./_appConfig";
import HomePage from "./pages/HomePage";
import Settings from "./pages/Settings";
import { Toaster } from "react-hot-toast";
import { SocketConnection } from "./lib/socketio-with-auth";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-datepicker/dist/react-datepicker.css";

function App() {
  return (
    <div className="App">
      <Suspense fallback={<div>Loading</div>}>
        <AuthComponents.Authentication
          config={{
            clientId: AppConfig.auth.clientId,
            applicationServer: AppConfig.api_server,
            localPrefix: AppConfig.app_storage_prefix,
          }}
        >
          <SocketConnection socketServer={AppConfig.socket_server}>
            <OrganisationProvider
              loadData={() => Authentication.userData.organisation}
            >
              <Toaster position="topright" />
              <Routes>
                <Route index element={<HomePage />} />
                <Route exact path="/settings" element={<Settings />} />
              </Routes>
            </OrganisationProvider>
          </SocketConnection>
        </AuthComponents.Authentication>
      </Suspense>
    </div>
  );
}

export default App;
